import './App.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import headerImg from "./img/6610138 1.svg"
import whyRPAimg from "./img/whyRPAimg.png"
import LaptopImg from "./img/laptop.png"
import EmailImg from "./img/email.png"
import PhoneImg from "./img/phone.png"

import logoSVG from "./img/Logo.svg"
import LogoWhite from "./img/LogoWhite.svg"
import RobotSvg from "./img/uil_robot.svg"
import IMBswg from "./img/IBM.svg"
import Microsoftswg from "./img/Microsoft.svg"
import PWCswg from "./img/PWC.svg"
import KPMGswg from "./img/KPMG.svg"
import Vector1 from "./img/Vector 1.svg"
import Vector2 from "./img/Vector 2.svg"
import RPAimg1 from "./img/RPAimg1.svg"
import RPAimg2 from "./img/RPAimg2.svg"
import RPAimg3 from "./img/RPAimg3.svg"
import RPAimg4 from "./img/RPAimg4.svg"
import RPAimg5 from "./img/RPAimg5.svg"
import BurgerMenuSvg from "./img/BurgerMenu.svg"
import robotBlackSvg from "./img/robotBlack.svg"

import Slider from 'react-slick';
import ProjectsInfo from './components/projectsInfo/projectsInfo';

import ProjectJSON from "./store/projectsData.json"
import AboutUsJSON from "./store/aboutUsData.json"

import AboutUsCard from './components/aboutUsCard/aboutUsCard';
import { useState } from 'react';

import { useFormik } from 'formik';
import * as Yup from "yup"

function App() {
  // React Slick Settings
  var settings = {
    dots: true,
    infinite: true,
    speed: 800,
    slidesToShow: 3,
    slidesToScroll: 3,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };
  const [aboutUsData, setAboutUsData] = useState(AboutUsJSON)
  const [phoneMenu, setPhoneMenu] = useState("d-none")

  // formik
  const formik = useFormik({
    initialValues: {
      firstname: "",
      lastname: "",
      email: ""
    },
    validationSchema:Yup.object({
      firstname: Yup.string().required().min(3).max(14),
      lastname:Yup.string().required().min(3).max(15),
      email:Yup.string().required().email()
    }),
    onSubmit: function (value) {
      alert("Данні з форми в консолі")
      console.log('====================================');
      console.log(value);
      console.log('====================================');
    }
  })

  function addPeople() {
    let result = [...aboutUsData, ...AboutUsJSON];
    setAboutUsData(result)
  }
  return (
    <div className="App">
      <header className='header'>


        <div className='header__navbar'>
          <div className="logo">
            <img src={logoSVG} alt="" />
          </div>
          {/* phone */}
          <div className='header__navbar__phone'>
            <img onClick={() => setPhoneMenu("d-block")} src={BurgerMenuSvg} alt="" />
          </div>
          <div className={'header__navbar__phone-menu ' + phoneMenu}>
            <nav>
              <div className='closeBnt' onClick={() => setPhoneMenu("d-none")}><span>✖</span></div>
              <div className="menu__link_phone">
                <span>
                  Про нас
                </span>
              </div>
              <div className="menu__link_phone">
                <span>
                  Портфоліо
                </span>
              </div>
              <div className="menu__link_phone">
                <span>
                  Сервіси
                </span>
              </div>
              <div className="menu__link_phone">
                <span>
                  Відгуки
                </span>
              </div>
              <div className="menu__link_phone">
                <span>
                  Контакти
                </span>
              </div>
              <div className='phoneMenu__btn-group'>
                <button className='header__buttons__btnLogin header__buttons__btnLogin__phone'>Увійти</button>
                <button className='header__buttons__btnRegister mainBtn'>Реeстрація</button>
              </div>
            </nav>
          </div>
          {/* phone */}



          <nav className='menu'>
            <div className="menu__link">Про нас</div>
            <div className="menu__link">Портфоліо</div>
            <div className="menu__link">Сервіси</div>
            <div className="menu__link">Відгуки</div>
            <div className="menu__link">Контакти</div>

          </nav>
          <div className='header__buttons'>
            <button className='header__buttons__btnLogin'>Увійти</button>
            <button className='header__buttons__btnRegister mainBtn'>Реeстрація</button>
          </div>
        </div>


        <div className='header__row'>
          <div className='header__col header__col__text'>
            <p className='header__col__title'>Автоматизуйте <br /> cвій бізнес з
              <span className='header__col__robo'>
                <img className='header__col__robo__icons' src={RobotSvg} alt="" />
              </span>
              <br />
              <span className='header__col__blue'>RPA технологіями</span></p>
            <p className='header__col__title2'>Революціонізуйте бізнес процеси з глобальною спільнотою професіоналів RPA за допомогою найсучасніших технологій RPA!</p>
            <div className='header__col__buttons'>
              <button className='mainBtn header__btn1'>Приєднатися до спільноти</button>
              <button className='mainBtn2 header__btn2'>Дізнатися більше</button>
            </div>
          </div>
          <div className='header__col header__col_img'>
            <img src={headerImg} alt="" />
          </div>
          <div className='header__row__scrollText'>продовжуйте гортати ▼</div>
        </div>
      </header>

      <main className='main'>
        <div className=' main__partners '>
          <div className="main-content__partners__item main__partners__item main__partners__text">
            <span className='main__partners__item__blue'>Наші</span>
            <div>
              Партнери
            </div>
          </div>

          <div className="main-content__partners__item">
            <img src={IMBswg} alt="" />
          </div>

          <div className="main-content__partners__item">
            <img src={Microsoftswg} alt="" />
          </div>

          <div className="main-content__partners__item">
            <img src={PWCswg} alt="" />
          </div>

          <div className="main-content__partners__item">
            <img src={KPMGswg} alt="" />
          </div>
        </div>
        <div>
          <img src={robotBlackSvg} alt="" />
        </div>
        <div className='main__whyRPA'>
          <div className='main__whyRPA__item main__whyRPA__item__img'>
            <img className='whyRPAimg' src={whyRPAimg} alt="" />
          </div>
          <div className='main__whyRPA__item main__whyRPA__item1'>
            <div className='main__whyRPA__item1__body'>
              <h4 className='main__whyRPA__item1__title'>
                Що таке RPA?
              </h4>
              <p className='main__whyRPA__item1__p'>
                Роботизація процесів (RPA) - це революційний підхід до автоматизації бізнес процесів, який дозволяє компаніям оптимізувати їх рутинні, повторювані та часоємні задачі. Завдяки потужним технологіям, RPA впроваджує "роботів" або програмне забезпечення, що може емулювати та виконувати завдання, які раніше виконували люди.
              </p>
              <button className='mainBtn main__btn1'>Приєднатися до спільноти</button>
            </div>
          </div>
        </div>

        <div className='main__advantages-RPA'>
          <div className='main__advantages-RPA__body'>
            <div className='main__advantages-RPA__title'>Переваги <span className='main__advantages-RPA__blue'>RPA</span>:</div>
            <img className='main__advantages-RPA__icons vector1' src={Vector1} alt="" />
            <div className='vector2'>
              <img className='main__advantages-RPA__icons ' src={Vector2} alt="" />
            </div>

            <div className="RPA__block RPA__block__item1">
              <img src={RPAimg1} alt="" />
              <div className='RPA__block__text'>
                <div className='RPA__block__title'>1. Підвищення продуктивності</div>
                <p className='RPA__block__p'>RPA звільняє від монотонних завдань, збільшуючи продуктивність команди та допомагає досягти кращих результатів.</p>
              </div>
            </div>

            <div className="RPA__block RPA__block__item2">
              <img src={RPAimg2} alt="" />
              <div className='RPA__block__text'>
                <div className='RPA__block__title'>2. Точність та надійність:</div>
                <p className='RPA__block__p'>Роботи виконують завдання з високою точністю та надійністю, уникнення помилок, уникаючи людського фактору</p>
              </div>
            </div>

            <div className="RPA__block RPA__block__item3">
              <img src={RPAimg3} alt="" />
              <div className='RPA__block__text'>
                <div className='RPA__block__title'>3. Швидкість та ефективність:</div>
                <p className='RPA__block__p'>RPA дозволяє оптимізувати процеси оскільки виконуе завдання значно швидше, ніж це здатний зробити людина. </p>
              </div>
            </div>

            <div className="RPA__block RPA__block__item4">
              <img src={RPAimg4} alt="" />
              <div className='RPA__block__text'>
                <div className='RPA__block__title'>4. Легка інтеграція:</div>
                <p className='RPA__block__p'> RPA інтегруеться з існуючими системами без необхідності великих змін або модифікацій, зберігаючи інфраструктуру компанії.</p>
              </div>
            </div>

            <div className="RPA__block RPA__block__item5">
              <img src={RPAimg5} alt="" />
              <div className='RPA__block__text'>
                <div className='RPA__block__title'>5. Скасування витрат</div>
                <p className='RPA__block__p'> RPA знижае витрати на робочу силу, забезпечуючи ефективніші робочі процеси та знижуючи кількість помилок.</p>
              </div>
            </div>
          </div>
        </div>

        <div className='main__projects'>
          <div className='main__projects__title'>Наші проекти</div>
          <div className="carusel">
            <Slider {...settings}>
              {
                ProjectJSON.map((proj) => {
                  return <ProjectsInfo key={proj.id} data={proj}></ProjectsInfo>
                })
              }
            </Slider>
          </div>
        </div>

        <div className='main__aboutUs'>
          <div className='title'>Про нас говорять</div>
          <div className='main__aboutUs__card'>
            {
              aboutUsData.map((i) => {
                return <AboutUsCard key={i.id + Math.random()} data={i}></AboutUsCard>
              })
            }
          </div>
          <div className='main__aboutUs__Btn-cont'>
            <button className='mainBtn main__aboutUs__btn' onClick={addPeople}>Читати всі</button>
          </div>
        </div>
        {/* form */}
        <form className="form" onSubmit={formik.handleSubmit}>
          <div className="title">Зворотний зв'язок</div>
          <div className="subtitle">Впиши свою пошту та отримай відповідь!</div>
          <div className="input-container ic1">
            <input id="firstname" name='firstname' className={`input  ${formik.errors.firstname && !formik.touched.firstname?"input__name__error":""}` } type="text" placeholder=" "
              
              onChange={formik.handleChange}
              onBlur={formik.onBlur}
              value={formik.values.firstname}
            />
            <div className="cut"></div>
            <label htmlFor="firstname" className="placeholder">І'мя</label>
          </div>
          <div className="input-container ic2">
            <input id="lastname" name='lastname'  className={`input  ${formik.errors.lastname && !formik.touched.lastname?"input__name__error":""}` } type="text" placeholder=" "
              onChange={formik.handleChange}
              onBlur={formik.onBlur}
              value={formik.values.lastname}
            />
            <div className="cut"></div>
            <label htmlFor="lastname" className="placeholder">Призвіще</label>
          </div>
          <div className="input-container ic2">
            <input id="email" name='email' className={`input  ${formik.errors.email && !formik.touched.email?"input__name__error":""}` } type="text" placeholder=" "
              
              onChange={formik.handleChange}
              onBlur={formik.onBlur}
              value={formik.values.email}
            />
            <div className="cut cut-short"></div>
            <label htmlFor="email" className="placeholder">Пошта</label>
          </div>
          <button type="submit" className="submit">Відправити</button>
        </form>
        {/* form */}

      </main >




      <footer className='footer'>
        <div className="footer__row">
          <div className='footerlogo__phone'>
            <img src={LogoWhite} alt="" />
          </div>
          <div className="footer__col footer__col__text">
            <img className='footer__logo' src={LogoWhite} alt="" />
            <div className='footer__col__text1'>Роботизація процесів (RPA) - це революційний підхід до автоматизації бізнес процесів, який дозволяє компаніям оптимізувати їх рутинні. Завдяки потужним технологіям, RPA впроваджує "роботів" або програмне забезпечення, що може емулювати та виконувати завдання, які раніше виконували люди.</div>
          </div>
          <div className="footer__col footer__col__li">
            <div>
              <li className='menu__link'>Про нас</li>
              <li className='menu__link'>Портфоліо</li>
              <li className='menu__link'>Сервіси</li>
            </div>
            <div>
              <li className='menu__link'>Відгуки</li>
              <li className='menu__link'>Контакти</li>
            </div>
          </div>
          <div className='footer__col footer__col__social-media'>
            <li>
              <img src={EmailImg} alt="" />
              <span>rpadevconnect@gmail.com</span>
            </li>
            <li>
              <img src={PhoneImg} alt="" />
              <span>1-888-484-3535 x3</span>
            </li>
            <li>
              <img src={LaptopImg} alt="" />
              <span>Підтримка</span>
            </li>
            <li className='footer__col__сity'>Kyiv, Ukraine</li>
          </div>
        </div>
        <div className='footer__line'></div>
        <div className="footer__row1">
          <li className="footer__row1__col menu__link">Privacy</li>
          <div className='footer__row1__line'></div>
          <li className="footer__row1__col menu__link">Do Not Sell My Personal Information</li>
          <div className='footer__row1__line'></div>
          <li className="footer__row1__col menu__link">Modern Slavery Statement</li>
          <div className='footer__row1__line'></div>
          <li className="footer__row1__col menu__link">Terms</li>
          <div className='footer__row1__line'></div>
          <li className="footer__row1__col menu__link">Trademark</li>
          <div className='footer__row1__line'></div>
          <li className="footer__row1__col menu__link">Certification & Compliance</li>
          <div className='footer__row1__line'></div>
          <li className="footer__row1__col menu__link">Vulnerability Disclosure Policy</li>
          <div className='footer__row1__line__last'></div>
        </div>
        <div className='footer__copy'>
          ©2023 RPADevConnect, Inc.
        </div>
      </footer>
    </div>
  );
}
export default App;
