import React from 'react'
import "./aboutUsCard.css"
const AboutUsCard = (props) => {
  return (
    <div className='AboutUsCard'>
        <img src={require(`../../img/${props.data.imgName}.png`)} alt="" />
        <div className='AboutUsCard__name'>
            <span>{props.data.name}</span><br />
            <span className='AboutUsCard__name__blue'>{props.data.profession}</span>
        </div>
        <div className='AboutUsCard__text'>
            {props.data.text}        
        </div>
    </div>
  )
}

export default AboutUsCard