import React, { useState } from 'react'
import "./projectsInfo.css"

const ProjectsInfo = (props) => {
  const [isFullText,setisFullText] = useState(false)
  return (
    <div className='box'>
      <div className='box__cont'>
      <img src={require(`../../img/${props.data.imgName}.png`)} alt="" />
      <div className='box__title'>
      {props.data.title}
      <div className='box__fullText'>
        {isFullText?props.data.FullText:null}
      </div>
      </div>
      
      <button className='box__btn' onClick={()=>setisFullText(!isFullText)}>{isFullText?"Приховати":"Дізнатися більше"}</button>
      </div>
    </div>
  )
}

export default ProjectsInfo
